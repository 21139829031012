body {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white {
  font-style: monospace;
  position: absolute;
  padding-right: 10px;
  color: white;
}

.icon-grey {
  fill: grey;
}

.typewriter h1 {
  overflow: hidden; 
  border-right: .15em solid orange; 
  white-space: nowrap;  
  letter-spacing: .11em; 
  animation: 
    typing 3.5s steps(30, end),
    blink-caret 1s step-end infinite;
}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}


@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

.btn-group-lg{
  padding-right: 5px;
}

.btn:hover {
  background-color: #5c5c5c;
  border-color: #5c5c5c; 
  
}

html {
  overflow-x: hidden;
  overflow-y: hidden;
}